<template>
  <div class="introduceWrapper">
    <div class="pcWrapper" v-if="!isMobile">
      <div class="firstBannerWrapper">
        <img src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/firstBack.png" alt="" />
      </div>
      <div class="secondWrapper">
        <div class="secontTitle">
          <WaveTitle :title="secondData.title" :subhead="secondData.subhead"></WaveTitle>
        </div>
        <div class="secContent">
          <div class="secRow">
            <div class="secItem" v-for="(item, index) in secondData.list" :key="index">
              <img class="showImage" :src="item.imgUrl" alt="" />
              <div class="nameDiv">{{ item.name }}</div>
              <BottomLine />
              <div class="introDiv">{{ item.intro }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="thirdWrapper">
        <div class="thirdTitle">
          <WaveTitle :title="thirdData.title" :subhead="thirdData.subhead"></WaveTitle>
        </div>
        <div class="thirdContent">
          <el-carousel trigger="click" indicator-position="outside" height="550px">
            <el-carousel-item>
              <div class="thirdRow">
                <div class="thirdItem" v-for="(item, index) in thirdData.list" :key="index + 40">
                  <img :src="item.imgUrl" alt="" />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="thirdRow">
                <div class="thirdItem" v-for="(item, index) in thirdData.list2" :key="index + 40">
                  <img :src="item.imgUrl" alt="" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="forthWrapper">
        <div class="forthTitle">
          <div class="forthTab">
            请允许介绍一下
          </div>
        </div>
        <div class="forthContent">
          <div class="forthBox">
            <div>艾融软件</div>
            <div>
              上海艾融软件股份有限公司是一家面向关键行业机构提供深度数字化整体解决方案的科技公司,是国家认证的高技术企业、软件企业，已通过CMMIDEV/5认证，ISO9001、ISO27001、ISO20000等质量、安全和服务体系认证。公司业务涵盖金融IT各领域，从商业银行核心业务系统、外围IT系统建设，到业务咨询、平台建设、安全保障、运营支撑、营销获客，助力客户实现数字化转型。旗下有艾融数据、宜签网络、艾融电子、砾阳软件（全资）、信立合创（全资）五家子公司。
            </div>
            <div class="forthBox-logo">
              <img src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/forth_logo1.svg" alt="">
              <img src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/forth_logo2.svg" alt="">
              <img src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/forth_logo3.svg" alt="">
              <img src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/forth_logo4.svg" alt="">
              <img src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/forth_logo5.svg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="fifthWrapper">
        <div class="fifthTitle">
          <WaveTitle :title="fifthData.title" :subhead="fifthData.subhead"></WaveTitle>
        </div>
        <div class="fifthContent">
          <div class="fifthRow">
            <div class="fifthItem" v-for="(item, index) in fifthData.list1" :key="index">
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
          <div class="fifthRow fifthRow-bottom">
            <div class="fifthItem" v-for="(item, index) in fifthData.list2" :key="index + 100">
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PhoneIndex v-else></PhoneIndex>
  </div>
</template>

<script>
import BottomLine from '@/components/BottomLine.vue';
import WaveTitle from '@/components/WaveTitle.vue';
import { isMobile } from "@/utils/index";
import PhoneIndex from './PhoneIndex.vue';

export default {
  components: { WaveTitle, BottomLine, PhoneIndex },
  data() {
    return {
      isMobile: false,
      secondData: {
        title: '我们可以为您提供的能力',
        subhead: 'Ability to provide',
        list: [
          {
            id: "13",
            name: "经验丰富",
            intro:
              "拥有十余年大型金融科技软件工程项目经验，服务大型金融机构客户数十家。拥有成熟、系统的方法论和产品线，可以为您考虑更多。",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/skills/skill01.svg",
          },
          {
            id: "7",
            name: "多地团队",
            intro:
              "在北京、上海、广州等地均拥有当地完整服务团队。客户在哪，我们就在哪。贴身全流程服务，保证项目顺畅高效。",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/skills/skill02.svg",
          },
          {
            id: "1",
            name: "整体解决方案",
            intro:
              "多元化产品涵盖金融IT各领域、汽车软件领域，基于互联网、大数据、机器学习、区块链、人工智能等先进技术应用，从无到有，从交付到运营，提供一揽子解决方案。",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/skills/skill03.svg",
          },
          {
            id: "20",
            name: "个性定制",
            intro:
              "拥有体系完善的在线存款,在线支付,在线贷款,在线运营,司法级在线身份认证等系列产品及企业级定制开发服务能力。",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/skills/skill04.svg",
          },
          {
            id: "21",
            name: "高效专业",
            intro: "成熟的项目经验和皮实的团队作风,基于您的需要给出最优解。",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/skills/skill05.svg",
          },
          {
            id: "22",
            name: "数字化系统集成",
            intro:
              "北交所上市企业,上千名员工服务于各大金融机构。可靠的技术及管理体系,确保万无一失。",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/skills/skill06.svg",
          },
        ],
      },
      thirdData: {
        title: '感谢这些客户使用我们的产品和服务',
        subhead: 'Our customers',
        list: [
          {
            id: "1",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client01.svg",
          },
          {
            id: "2",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client02.svg",
          },
          {
            id: "3",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client03.svg",
          },
          {
            id: "4",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client04.svg",
          },
          {
            id: "5",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client05.svg",
          },
          {
            id: "6",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client06.svg",
          },
          {
            id: "7",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client07.svg",
          },
          {
            id: "8",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client08.svg",
          },
          {
            id: "9",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client09.svg",
          },
          {
            id: "10",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client10.svg",
          },
          {
            id: "11",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client11.svg",
          },
          {
            id: "12",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client12.svg",
          },
        ],
        list2: [
          {
            id: "1",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/youliBank.png",
          },
          {
            id: "2",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/msBank.png",
          },
          {
            id: "3",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/xmBank.png",
          },
          {
            id: "4",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/nbBank.png",
          },
          {
            id: "5",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/hxBank.png",
          },
          {
            id: "6",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/gxBank.png",
          },
          {
            id: "7",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/tdBank.png",
          },
          {
            id: "8",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/tpBank.png",
          },
        ],
      },
      fifthData: {
        title: '与我们共同前行的伙伴们',
        subhead: 'Our partner',
        list1: [
          {
            id: "1",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partner01.svg",
          },
          {
            id: "2",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partner02.svg",
          },
          {
            id: "3",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partner03.svg",
          },

        ],
        list2: [
          {
            id: "4",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partner04.svg",
          },
          {
            id: "1",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partner05.svg",
          },
          {
            id: "2",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partner06.svg",
          },
          {
            id: "3",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partner07.svg",
          },
        ],
      }
    };
  },
  created() { },
  computed: {
    secondDataList1() {
      return this.m_secondDataList.slice(0, 3);
    },
    secondDataList2() {
      return this.m_secondDataList.slice(-3);
    },
  },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.introduceWrapper {
  background: #ffffff;

  .pcWrapper {
    .firstBannerWrapper {
      position: relative;

      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        height: auto;
      }

      .textBox {
        position: absolute;
        top: 212px;
        left: 129px;
        width: 460px;
        height: 210px;
        // background: tomato;
        text-align: left;

        .firstRow1 {
          color: #fff;
          font-size: 40px;
          font-weight: bold;
        }

        .firstRow2 {
          color: #fff;
          font-size: 40px;
          font-weight: bold;
        }

        .firstRow3 {
          color: #fff;
          font-size: 40px;
        }
      }
    }

    .secondWrapper {
      padding: 96px 0;

      .secontTitle {
        text-align: center;
        font-size: 30px;
        // margin-top: 50px;
        margin-bottom: 50px;
      }

      .secContent {
        width: 75%;
        margin: 24px auto;

        .secRow {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-items: center;
          justify-items: center;
          row-gap: 60px;

          .secItem {
            cursor: pointer;
            width: 226px;
            line-height: normal;
            text-align: center;
            letter-spacing: 0em;
            color: #001F34;

            // .showImage {
            //   width: 150px;
            //   height: 150px;
            // }

            .nameDiv {
              font-size: 16px;
              font-weight: 900;
            }

            .introDiv {
              margin: 0 auto;
              width: 226px;
              font-size: 14px;
              font-weight: normal;
              opacity: 0;
            }
          }

          .secItem:hover {
            .introDiv {
              opacity: 1;
            }
          }
        }
      }
    }

    .thirdWrapper {
      padding: 96px 0;
      background: #F1F1F1;

      .thirdTitle {
        margin-bottom: 48px;
      }

      .thirdContent {
        width: 75%;
        margin: 24px auto;

        .thirdRow {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: center;
          justify-items: center;

          img {
            width: 100%;
          }
        }
      }
    }

    .forthWrapper {
      background-image: url("https://img.i2soft.net/i2official-web/v2/assets/images/introduce/introBack.png");
      // height: 545px;
      padding: 160px 0;
      color: #fff;

      .forthTitle {
        // text-align: center;
        // font-size: 30px;
        // margin-top: 48px;
        // margin-bottom: 48px;
        // color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 33px;

        .forthTab {
          width: 242px;
          padding: 4px;
          font-size: 32px;
          font-weight: normal;
          background: linear-gradient(104deg, #E6D2B2 0%, #B08D57 101%);

        }
      }

      .forthContent {
        width: 75%;
        margin: 24px auto;

        .forthBox {
          padding: 30px 44px;
          background: rgba(255, 255, 255, 0.3);

          box-sizing: border-box;
          border: 1px solid #FFFFFF;

          backdrop-filter: blur(91px);

          div {
            font-size: 14px;
          }

          div:first-child {
            font-size: 72px;
            font-weight: bold;
            margin-bottom: 24px;
          }

          .forthBox-logo {
            margin-top: 46px;
            padding: 33px 0px;
            display: grid;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 20px;
            row-gap: 20px;
            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            background: rgba(255, 255, 255, 0.7);

            img {
              min-width: 133.21px;
              min-height: 32px;
            }
          }
        }
      }
    }

    .fifthWrapper {
      background-image: url("https://img.i2soft.net/i2official-web/v2/assets/images/introduce/partner/partnerBack.png");
      padding: 96px 0;

      img {
        width: 100%;
        height: auto;
        float: left;
      }

      .fifthTitle {
        margin-bottom: 24px;
      }

      .fifthContent {
        .fifthRow {
          width: 100%;
          display: flex;
          justify-content: center;

          .fifthItem {
            width: 230px;
            height: 230px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .fifthRow-bottom {
          margin-top: -100px;
          z-index: 1;
        }
      }
    }
  }

}

/deep/.el-carousel__indicator.is-active button {
  opacity: 1;
  width: 120px;
  height: 10px;
  border-radius: 5px;
  background: #E0AB65;
}

/deep/.el-carousel__button {
  opacity: 1;
  background: #E0AB65;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
</style>
